import {
  KEY_AUTO_DECORATE,
  KEY_DOWNLOAD_TRACKING,
  KEY_OUTGOING_IGNORE_SUBDOMAIN,
  KEY_OUTGOING_TRACKING
} from './constants';

export default {
  [KEY_AUTO_DECORATE]: undefined,
  [KEY_DOWNLOAD_TRACKING]: false,
  [KEY_OUTGOING_IGNORE_SUBDOMAIN]: true,
  [KEY_OUTGOING_TRACKING]: false
};
